@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap);
/* GOOGLE FONTS */

/* VARIABLES CSS */
:root {
    --header-height: 3rem;

    /* Colors */
    /* Change base color */
    --hue-color: 230; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

    /* HSL color mode */
    --first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
    --scroll-thumb-color-hover: hsl(var(--hue-color), 8%, 65%);
    --container-color: white;
    --notification-success-color: hsla(0, 0%, 97%, .5);
    --terminal-top-color: rgb(56, 52, 60);
    --terminal-main-color: rgb(37, 41, 45);
    --terminal-title-color: hsl(0, 0%, 61%);
    --terminal-button-red: red;
    --terminal-button-yellow: yellow;
    --terminal-button-green: rgb(0, 255, 0);
    --terminal-caret-color: hsl(0, 0%, 61%);
    --terminal-main-text-color: hsl(0, 0%, 100%);
    --country-code-color: rgb(96, 96, 96);
    --terminal-border-color: hsl(276, 6.2%, 31.4%);
    --terminal-scroll-bar-color: hsl(var(--hue-color), 12%, 20%);
    --terminal-scroll-thumb-color: hsl(var(--hue-color), 12%, 38%);
    --terminal-scroll-thumb-color-hover: hsl(var(--hue-color), 12%, 48%);

    /* Font and typography */
    --body-font: 'Poppins', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;
    --nav-font-size: 1.25rem;
    --terminal-font-size: .65rem;

    /* Font weight */
    --font-medium: 500;
    --font-semi-bold: 600;

    /* Margenes Bottom */
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /* z index */
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
    --z-notification: 1010;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
        --nav-font-size: 1.3rem;
        --terminal-font-size: .75rem;
    }
}

/* Variables Dark Theme */
body.dark-theme{
    /* HSL color mode */
    --first-color-second: hsl(var(--hue-color), 30%, 8%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 28%, 12%);
    --container-color: hsl(var(--hue-color), 29%, 16%);
    --scroll-bar-color: hsl(var(--hue-color), 12%, 20%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 38%);
    --scroll-thumb-color-hover: hsl(var(--hue-color), 12%, 48%);
    --notification-success-color: hsla(var(--hue-color), 29%, 25%, .5);
    --terminal-top-color: hsl(265, 13.6%, 17.3%);
    --terminal-main-color: rgb(28, 28, 28);
    --terminal-title-color: hsl(265, 5%, 42%);
    --country-code-color: rgb(170, 170, 170);
    --terminal-top-color: rgb(56, 52, 60);
    --terminal-main-color: rgb(30, 30, 30);
    --terminal-title-color: hsl(0, 0%, 61%);
    --terminal-button-red: red;
    --terminal-button-yellow: yellow;
    --terminal-button-green: rgb(0, 255, 0);
    --terminal-caret-color: hsl(0, 0%, 61%);
    --terminal-main-text-color: hsl(0, 0%, 100%);
    --terminal-border-color: hsl(276, 6.2%, 31.4%);
    --terminal-scroll-bar-color: hsl(var(--hue-color), 12%, 20%);
    --terminal-scroll-thumb-color: hsl(var(--hue-color), 12%, 38%);
    --terminal-scroll-thumb-color-hover: hsl(var(--hue-color), 12%, 48%);
}

/* Binary Clock */
.inactive
{
	background-color:rgb(68, 77, 88);
}

.active
{
	background-color:rgb(45, 179, 90);
}		

.inactive, .active, .res
{
	width: .5rem;
	height: .5rem;
	color: black;
	border-radius: 50%;
	font-size: 0rem;
}

.res
{
	font-weight:bold;
	background-color:black;
	color:white;
	display: none;
	font-size: 0rem;
}

.onProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.onProgressContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
}

/* Button Dark/Light */
.nav_btns{
    display: flex;
    align-items: center;
}

.change-theme{
    font-size: 1.25rem;
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    margin-right: 1rem;
    margin-right: var(--mb-1);
    cursor: pointer;
}

.change-theme:hover{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

/* BASE */
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html{
    scroll-behavior: smooth;
}

body{
    margin: 0 0 3rem 0;
    margin: 0 0 var(--header-height) 0;
    font-family: 'Poppins', sans-serif;
    font-family: var(--body-font);
    font-size: .938rem;
    font-size: var(--normal-font-size);
    background-color: hsl(230, 60%, 99%);
    background-color: var(--body-color);
    color: hsl(230, 8%, 45%);
    color: var(--text-color);
}

h1,h2,h3,h4{
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    font-weight: 600;
    font-weight: var(--font-semi-bold);
}

ul{
    list-style: none;
}

a{
    text-decoration: none;
}

img{
    max-width: 100%;
    height: auto;
}

/* REUSABLE CSS CLASSES */
.section{
    padding: 2rem 0 4rem;
}

.section_title{
    font-size: 1.5rem;
    font-size: var(--h1-font-size);
}

.section_subtitle{
    display: block;
    font-size: .813rem;
    font-size: var(--small-font-size);
    margin-bottom: 3rem;
    margin-bottom: var(--mb-3);
}

.section_title,
.section_subtitle{
    text-align: center;
}

/* LAYOUT */
.container{
    max-width: 1200px;
    margin-left: 1.5rem;
    margin-left: var(--mb-1-5);
    margin-right: 1.5rem;
    margin-right: var(--mb-1-5);
}

.grid{
    display: grid;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.header{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    z-index: var(--z-fixed);
    background-color: hsl(230, 60%, 99%);
    background-color: var(--body-color);
}

/* NAV */
.nav{
    max-width: 1200px;
    height: 3rem;
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_logo,
.nav_toggle{
    display: flex;
    font-size: 1.25rem;
    font-size: var(--nav-font-size);
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    font-weight: 600;
    font-weight: var(--font-semi-bold);
}

.nav_logo:hover{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.nav_toggle{
    font-size: 1.1rem;
    cursor: pointer;
}

.nav_toggle:hover{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.country_code {
    font-size: calc(.75rem - .1rem);
    font-size: calc(var(--smaller-font-size) - .1rem);
    font-weight: 100;
    padding-left: 0rem;
    color: rgb(96, 96, 96);
    color: var(--country-code-color);
    text-transform: uppercase
}

@media screen and (max-width: 767px){
    .nav_menu{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: hsl(230, 60%, 99%);
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }
}

.nav_list{
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    gap: 2rem
}

.nav_link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .813rem;
    font-size: var(--small-font-size);
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    font-weight: 500;
    font-weight: var(--font-medium);
}

.nav_link:hover{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.nav_icon{
    font-size: 1.2rem;
}

.nav_close{
    position: absolute;
    right: 1.3rem;
    bottom: .5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.nav_close:hover{
    color: hsl(230, 57%, 53%);
    color: var(--first-color-alt);
}

/* show nav */
.show-menu{
    bottom: 0;
}

/* Active Link */
.active-link{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

/* CHANGE BACKGROUND HEADER */
.scroll-header{
    box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
}

/* Language Switch */
.language_switch_container {
    position: absolute;
    left: 10rem;
    bottom: 2rem;
}

.language_switch_box {
    background-color: hsla(0, 0%, 97%, .5);
    background-color: var(--notification-success-color);
    width: 10rem;
    height: 4.5rem;
    border-radius: .75rem;
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    z-index: 3;
}

.language_switch_content {
    display: flex;
    grid-gap: 1.75rem;
    gap: 1.75rem;
    padding-left: 1rem;
    padding-top: .5rem;
    z-index: 4;
    cursor: pointer;
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
}

.language_en:hover {
    color: hsl(230, 69%, 61%) !important;
    color: var(--first-color) !important;
}

.language_de:hover {
    color: hsl(230, 69%, 61%) !important;
    color: var(--first-color) !important;
}

.language_flag {
    width: 50px;
    height: 33px;
    border-radius: .25rem;
}

.language_title {
    color: auto;
    font-size: .813rem;
    font-size: var(--small-font-size);
    text-align: center;
}

/* NOTIFICATION EMAIL SEND */
.notification_container{
    display: absolute;
    cursor: pointer;
}

.nav_close-area {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: blue;
    z-index: 100;
}

.notification{
    padding-left: .8rem;
    background-color: hsla(0, 0%, 97%, .5);
    background-color: var(--notification-success-color);
    width: 23.5rem;
    position: fixed;
    right: 50%;
    left: 50%;
    top: -100%;
    -webkit-transform: translate(-50%, 1rem);
            transform: translate(-50%, 1rem);
    z-index: 1010;
    z-index: var(--z-notification);
    border-radius: 1rem;
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    box-shadow: 0 0px 100px rgba(0, 0, 0, .25);
    overflow: hidden;
}

.notification_content{
    display: flex;
}

.notification_icon{
    font-size: 3rem;
    color: hsl(230, 8%, 45%);
    color: var(--text-color);
    padding-right: .75rem;
}

.text_notification{
    margin-top: .65rem;
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    padding-bottom: .5rem;
}

.title_notification{
    font-weight: 600;
    font-weight: var(--font-semi-bold);
}

.progress_bar_notification{
    position: absolute;
    -webkit-transform: translateY(-.1rem);
            transform: translateY(-.1rem);
    left: 0;
    width: 0%;
    height: .1rem;
}

#progress_bar_email_success{
    background-color: greenyellow;
}

#progress_bar_email_error{
    background-color: red;
}

.notification.notification_show{
    -webkit-animation: notification_show 1s ease forwards;
            animation: notification_show 1s ease forwards;
}

@-webkit-keyframes notification_show {
    100% {
        top: 0%;
    }
}

@keyframes notification_show {
    100% {
        top: 0%;
    }
}

.notification.notification_hide{
    -webkit-animation: notification_hide 2s ease forwards;
            animation: notification_hide 2s ease forwards;
}

@-webkit-keyframes notification_hide {
    0% {
        top: 0%;
    }
    100% {
        top: -100%;
    }
}

@keyframes notification_hide {
    0% {
        top: 0%;
    }
    100% {
        top: -100%;
    }
}

.progress_bar_notification.notification_show{
    -webkit-animation: progress_bar 6s ease forwards;
            animation: progress_bar 6s ease forwards;
}

@-webkit-keyframes progress_bar {
    0% {
        width: 130%;
    }
    100% {
        width: 0%;
    }
}

@keyframes progress_bar {
    0% {
        width: 130%;
    }
    100% {
        width: 0%;
    }
}

/* HOME */
.home_container{
    grid-gap: 1rem;
    gap: 1rem;
}

.home_content{
    grid-template-columns: .5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
}

.home_social{
    display: grid;
    grid-template-columns: -webkit-max-content;
    grid-template-columns: max-content;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.home_social-icon{
    font-size: 1.25rem;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.home_social-icon:hover{
    color: hsl(230, 57%, 53%);
    color: var(--first-color-alt);
}

.home_blob{
    width: 250px;
    fill: hsl(230, 69%, 61%);
    fill: var(--first-color);
}

.home_blob-img{
    width: 250px;
    border-radius: 2rem;

}

.home_data{
    grid-column: 1/3;
}

.home_title{
    font-size: 2rem;
    font-size: var(--big-font-size);
}

.home_subtitle{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    color: hsl(230, 8%, 45%);
    color: var(--text-color);
    font-weight: 500;
    font-weight: var(--font-medium);
    margin-bottom: .75rem;
    margin-bottom: var(--mb-0-75);
}

.home_description{
    margin-bottom: 2rem;
    margin-bottom: var(--mb-2);
}

.home_scroll{
    display: none;
}

.home_scroll-button{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
    transition: .3s;
}

.home_scroll-button:hover{
    -webkit-transform: translateY(.25rem);
            transform: translateY(.25rem);
}

.home_scroll-name{
    font-size: .813rem;
    font-size: var(--small-font-size);
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    font-weight: 500;
    font-weight: var(--font-medium);
    margin-right: .25rem;
    margin-right: var(--mb-0-25);
}

.home_scroll-arrow{
    font-size: 1.25rem;
}

/* BUTTONS */
.button{
    display: inline-block;
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color);
    color: white;
    padding: 1rem;
    border-radius: .5rem;
    font-weight: 500;
    font-weight: var(--font-medium);
    cursor: pointer;
}

.button:hover{
    background-color: hsl(230, 57%, 53%);
    background-color: var(--first-color-alt);
}

.button_icon{
    font-size: 1.25rem;
    margin-left: .5rem;
    margin-left: var(--mb-0-5);
    transition: .3s;
}

.button--white{
    background-color: white;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.button--white:hover{
    background-color: white;
}

.button--flex{
    display: inline-flex;
    align-items: center;
}

.button--small{
    padding: .75rem 1rem;
}

.button--link{
    padding: 0;
    background-color: transparent;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.button--link:hover{
    background-color: transparent;
    color: hsl(230, 57%, 53%);
    color: var(--first-color-alt);
}

/* ABOUT */
.about_img{
    width: 200px;
    border-radius: .5rem;
    justify-self: center;
    align-self: center;
}

.about_description{
    text-align: center;
    margin-bottom: 2.5rem;
    margin-bottom: var(--mb-2-5);
}

.about_info{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2.5rem;
    margin-bottom: var(--mb-2-5);
}

.about_info-title{
    font-size: 1.25rem;
    font-size: var(--h2-font-size);
    font-weight: 600;
    font-weight: var(--font-semi-bold);
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
}

.about_info-name{
    font-size: .75rem;
    font-size: var(--smaller-font-size);
    white-space: pre-line;  
    vertical-align: bottom;
}

.about_info-title,
.about_info-name{
    display: block;
    text-align: center;
}

.about_buttons{
    display: flex;
    justify-content: center;
}

/* SKILLS */
.skills_container{
    grid-row-gap: 0;
    row-gap: 0;
}

.skills_header{
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    margin-bottom: var(--mb-2-5);
    cursor: pointer;
}

.skills_icon,
.skills_arrow{
    font-size: 2rem;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.skills_icon{
    margin-right: .75rem;
    margin-right: var(--mb-0-75);
}

.skills_title{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
}

.skills_subtitle{
    font-size: .813rem;
    font-size: var(--small-font-size);
    color: hsl(230, 8%, 65%);
    color: var(--text-color-light);
}

.skills_arrow{
    margin-left: auto;
    transition: .4s;
}

.skills_list{
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
    padding-left: 2.7rem;
}

.skills_titles{
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    margin-bottom: var(--mb-0-5);
}

.skills_name{
    font-size: .938rem;
    font-size: var(--normal-font-size);
    font-weight: 500;
    font-weight: var(--font-medium);
}

.skills_bar,
.skills_percentage{
    height: 5px;
    border-radius: .25rem;
}

.skills_bar{
    background-color: hsl(230, 92%, 85%);
    background-color: var(--first-color-lighter);
}

.skills_percentage{
    display: block;
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color);
}

.skills_sub1Skill1{
    width: 50%;
}

.skills_sub2Skill1{
    width: 50%;
}

.skills_sub3Skill1{
    width: 50%;
}

.skills_sub1Skill2{
    width: 50%;
}

.skills_sub2Skill2{
    width: 50%;
}

.skills_sub3Skill2{
    width: 50%;
}

.skills_sub1Skill3{
    width: 50%;
}

.skills_sub2Skill3{
    width: 50%;
}

.skills_sub3Skill3{
    width: 50%;
}

.skills_close .skills_list{
    height: 0;
    overflow: hidden;
}

.skills_open .skills_list{
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 2.5rem;
    margin-bottom: var(--mb-2-5);
}

.skills_open .skills_arrow{
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

/* QUALIFICATION */
.qualification_tabs{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2rem;
    margin-bottom: var(--mb-2);
}

.qualification_button{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    font-weight: 500;
    font-weight: var(--font-medium);
    cursor: pointer;
}

.qualification_button:hover{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.qualification_icon{
    font-size: 1.8rem;
    margin-right: .25rem;
    margin-right: var(--mb-0-25);
}


.qualification_data{
    display: grid;
    grid-template-columns: 1fr -webkit-max-content 1fr;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
}

.qualification_title{
    font-size: .938rem;
    font-size: var(--normal-font-size);
    font-weight: 500;
    font-weight: var(--font-medium);
}

.qualification_subtitle{
    display: inline-block;
    font-size: .813rem;
    font-size: var(--small-font-size);
    margin-bottom: 1rem;
    margin-bottom: var(--mb-1);
}

.qualification_calendar{
    font-size: .75rem;
    font-size: var(--smaller-font-size);
    color: hsl(230, 8%, 65%);
    color: var(--text-color-light);
}

.qualification_rounder{
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color);
    border-radius: 50%;
}

.qualification_line{
    display: block;
    width: 1px;
    height: 100%;
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color);
    -webkit-transform: translate(6px, -7px);
            transform: translate(6px, -7px);
}

.qualification [data-content]{
    display: none;
}

.qualification_active[data-content]{
    display: block;
}

.qualification_button.qualification_active{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

/* SERVICES */
.close_area {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
}

.services_container{
    grid-gap: 1.5rem;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
}

.services_content{
    position: relative;
    background-color: white;
    background-color: var(--container-color);
    padding: 3.5rem .5rem 1.25rem 1.5rem;
    border-radius: 1.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
    transition: .3s;
}

.services_content:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, .30);
}

.services_icon{
    display: block;
    font-size: 1.5rem;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
    margin-bottom: 1rem;
    margin-bottom: var(--mb-1);
}

.services_title{
    white-space: pre-line;  
    vertical-align: bottom;
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    margin-bottom: var(--mb-1);
    font-weight: 500;
    font-weight: var(--font-medium);
}

.services_button{
    cursor: pointer;
    font-size: .813rem;
    font-size: var(--small-font-size);
}

.services_button:hover .button_icon{
    -webkit-transform: translateX(.25rem);
            transform: translateX(.25rem);
}

.services_modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    z-index: 1000;
    z-index: var(--z-modal);
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.services_modal-content{
    position: relative;
    background-color: white;
    background-color: var(--container-color);
    padding: 1.5rem;
    border-radius: 1rem;
}

.services_modal-services{
    grid-row-gap: 1rem;
    row-gap: 1rem;
}

.services_modal-service{
    display: flex;
}

.services_modal-title{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    font-weight: 500;
    font-weight: var(--font-medium);
    margin-bottom: 1rem;
    margin-bottom: var(--mb-1);
    white-space: pre-line;  
    vertical-align: bottom;
}

.services_modal-close{
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 1.5rem;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
    cursor: pointer;
}

.services_modal-icon{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
    margin-right: .25rem;
    margin-right: var(--mb-0-25);
}

/* Active Modal */
.active-modal{
    opacity: 1;
    visibility: visible;
}

/* PORTFOLIO */
.portfolio_container{
    overflow: visible;
    overflow: initial;
}

.portfolio_content{
    padding: 0 2.5rem;
}

.portfolio_img{
    width: 310px;
    border-radius: 1rem;
    justify-self: center;
}

.portfolio_title{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    margin-bottom: .5rem;
    margin-bottom: var(--mb-0-5);
}


.portfolio_description{
    margin-bottom: .75rem;
    margin-bottom: var(--mb-0-75);
}

.portfolio_button:hover .button_icon{
    -webkit-transform: translateX(.3rem);
            transform: translateX(.3rem);
}

.portfolio_heading {
    position: -webkit-sticky;
    position: sticky;
    top: 7%;
}

.card{
    position: -webkit-sticky;
    position: sticky;
    top: 20%;
}

.portfolio_title {
    font-size: 1.5rem;
    font-size: var(--h1-font-size);
}
.card_container {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    /* position: relative; */
    display: grid;
    /* grid-template-rows: repeat(2, 1fr); */
    background-color: white;
    background-color: var(--container-color);
    padding: 0rem 2rem 1.25rem 2rem;
    margin: 0rem 1.5rem 5rem 1.5rem;
    border-radius: 1.25rem;
    box-shadow: 0 2px 50px rgba(0, 0, 0, .15);
}
/* 
.card_spacer {
    height: 10rem;
} */

.card_left {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    order: 2;
    max-width: 40rem;
}

.card_right {
    order: 1;
    padding-top: 3rem;
}

.card_img {
    width: 100%;
    border-radius: 1.25rem;
}

.card_icon {
    display: none;
    font-size: calc(2rem + 1rem);
    font-size: calc(var(--big-font-size) + 1rem);
}

.card_header {
    font-size: calc(1.5rem + .5rem);
    font-size: calc(var(--h1-font-size) + .5rem);
}

.card_subheader {
    text-transform: uppercase;
    font-size: .813rem;
    font-size: var(--small-font-size);
    -webkit-transform: translateY(-.2rem);
            transform: translateY(-.2rem);
}

.card_text {
    margin-top: 1rem;
}

.swiper-button-prev::after,
.swiper-button-next::after{
    display: none;
}

.swiper-portfolio-icon{
    font-size: 2rem;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.swiper-button-prev{
    left: -.5rem;
}

.swiper-button-next{
    right: -.5rem;
}

.swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: -2.5rem;
}

.swiper-pagination-bullet-active{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullets{
    outline: none;
}

/* PROJECT IN MIND*/
.project{
    text-align: center;
}

.project_bg{
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color-second);
    padding-top: 3rem;
}

.project_title{
    font-size: 1.25rem;
    font-size: var(--h2-font-size);
    margin-bottom: .75rem;
    margin-bottom: var(--mb-0-75);
}

.project_description{
    margin-bottom: 1.5rem;
    margin-bottom: var(--mb-1-5);
}

.project_title,
.project_description{
    color: white;
}

.project_img{
    width: 232px;
    justify-self: center;
    border-radius: 1.25rem;
    margin-bottom: 3rem;
}

/* TESTIMONIAL */
.testimonial_data,
.testimonial_header{
    display: flex;
}

.testimonial_data{
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-bottom: var(--mb-1);
}

.testimonial_img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: .75rem;
    margin-right: var(--mb-0-75);
}

.testimonial_name{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    font-weight: 500;
    font-weight: var(--font-medium);
}

.testimonial_client{
    font-size: .813rem;
    font-size: var(--small-font-size);
    color: hsl(230, 8%, 65%);
    color: var(--text-color-light);
}

.testimonial_description{
    margin-bottom: 2.5rem;
    margin-bottom: var(--mb-2-5);
    margin-left: 1.5rem;
    margin-left: var(--mb-1-5);
}

.testimonial_icon-star{
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
}

.swiper-container .swiper-pagination-testimonial{
    bottom: 0;
}


/* CONTACT ME */
.contact_container{
    grid-row-gap: 3rem;
    row-gap: 3rem;
}

.contact_information{
    display: flex;
    margin-bottom: 2rem;
    margin-bottom: var(--mb-2);
}

.contact_icon{
    font-size: 2rem;
    color: hsl(230, 69%, 61%);
    color: var(--first-color);
    margin-right: .75rem;
    margin-right: var(--mb-0-75);
}

.contact_title{
    font-size: 1.125rem;
    font-size: var(--h3-font-size);
    font-weight: 500;
    font-weight: var(--font-medium);
}

.contact_subtitle{
    font-size: .813rem;
    font-size: var(--small-font-size);
    color: hsl(230, 8%, 45%);
    color: var(--text-color);
}

.contact_content{
    background-color: hsl(230, 70%, 96%);
    background-color: var(--input-color);
    border-radius: 1rem;
    padding: .75rem 1rem .25rem;
}

.contact_label{
    font-size: .75rem;
    font-size: var(--smaller-font-size);
    color: hsl(230, 8%, 65%);
    color: var(--text-color-light);
}

.contact_input{
    width: 100%;
    background-color: hsl(230, 70%, 96%);
    background-color: var(--input-color);
    color: hsl(230, 8%, 15%);
    color: var(--title-color);
    font-family: 'Poppins', sans-serif;
    font-family: var(--body-font);
    font-size: .938rem;
    font-size: var(--normal-font-size);
    border: none;
    outline: none;
    padding: .25rem .5rem .5rem 0;
    resize: none;
}

.email_not_valid{
    border: .1rem red solid;
}

.contact_button_container {
    display: flex;
}

.email_active_show {
    display: unset;
}

.notification_counter {
    display: flex;
    grid-gap: .3rem;
    gap: .3rem;
}

.temrminal {
    display: auto;
}

.hide {
    display: none;
}
.terminal_container {
    margin-top: 3rem !important;
    background-color: black;
    border: solid hsl(276, 6.2%, 31.4%) .001rem;
    border: solid var(--terminal-border-color) .001rem;
    border-radius: 1rem;
    max-width: 40rem;
    margin: auto auto;
}

/* .terminal_content {
    width: max-content;
} */

.terminal_top {
    display: flex;
    background-color: rgb(56, 52, 60);
    background-color: var(--terminal-top-color);
    height: 2rem;
    width: 100%;
    text-align: center;
    padding-top: .40005rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.terminal_title {
    width: 100%;
    text-align: center;
    font-size: .813rem;
    font-size: var(--small-font-size);
    font-weight: 600;
    font-weight: var(--font-semi-bold);
    color: hsl(0, 0%, 61%);
    color: var(--terminal-title-color);
}

.terminal_buttons {
    display: flex;
    margin-left: .75rem;
    margin-top: .25rem;
    grid-gap: .35rem;
    gap: .35rem;
}

.terminal_button_red {
    background-color: red;
    background-color: var(--terminal-button-red);
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
}

.terminal_button_yellow {
    background-color: yellow;
    background-color: var(--terminal-button-yellow);
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
}

.terminal_button_green {
    background-color: rgb(0, 255, 0);
    background-color: var(--terminal-button-green);
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
}

/* .terminal_buttons:hover .close_button_1 {
    background-color: black;
    width: .05rem;
    height: .57rem;
    margin-left: .357rem;
    margin-top: .065rem;
    transform: rotate(45deg);
}

.terminal_buttons:hover .close_button_2 {
    background-color: black;
    width: .05rem;
    height: .57rem;
    margin-left: .357rem;
    margin-top: .09rem;
    transform: rotate(-45deg) translateY(-.48rem) translateX(.45rem) !important;
} */

.terminal_main {
    background-color: rgb(37, 41, 45);
    background-color: var(--terminal-main-color);
    width: 100%;
    height: 18rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow-y: scroll;
}

.terminal_main::-webkit-scrollbar {
    display: none;
}

.terminal_command_container {
    display: flex inline-block;
    max-width: 100%;
    word-wrap: break-word;
    font-family: 'Roboto Mono', monospace;
    font-size: .65rem;
    font-size: var(--terminal-font-size);
    font-weight: 300;
    color: hsl(0, 0%, 100%);
    color: var(--terminal-main-text-color);
    padding-left: .3rem;
}

.terminal_command {
    width: auto;
}

.terminal_input::-webkit-scrollbar {
    display: none;
}

.terminal_command_text {
    width: -webkit-max-content;
    width: max-content;
    word-wrap: break-word;
    white-space: 1px;
    margin-left: .2rem;
}

.terminal_caret {
    display: inline-block;
    border: solid hsl(0, 0%, 61%) .5px;
    border: solid var(--terminal-caret-color) .5px;
    width: .5rem;
    height: 1rem;
    -webkit-transform: translate(.2rem, .2rem);
            transform: translate(.2rem, .2rem);
}

.terminal_caret_focus {
    background-color: hsl(0, 0%, 61%);
    background-color: var(--terminal-caret-color);
    -webkit-transform: translate(.2rem, .2rem);
            transform: translate(.2rem, .2rem);
    border: none;
}

.terminal_input {
    position: absolute;
    padding-left: .5rem;
    background-color: rgb(37, 41, 45);
    background-color: var(--terminal-main-color);
    border: none;
    outline: none;
    box-sizing: border-box;
    -webkit-transform: translateY(-20rem);
            transform: translateY(-20rem);
    height: 0%;
    width: 100%;
    overflow: visible;
    resize: none;
}

/* FOOTER */
.footer{
    padding-top: 2rem;
}

.footer_container{
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
}

.footer_bg{
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color-second);
    padding: 2rem 0 3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.footer_title{
    font-size: 1.5rem;
    font-size: var(--h1-font-size);
    margin-bottom: .25rem;
    margin-bottom: var(--mb-0-25);
}

.footer_subtitle{
    font-size: .813rem;
    font-size: var(--small-font-size);
}

.footer_links{
    display: flex;
    flex-direction: column;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
}

.footer_link:hover{
    color: hsl(230, 92%, 85%);
    color: var(--first-color-lighter);
}

.footer_social{
    font-size: 1.25rem;
    margin-right: 1.5rem;
    margin-right: var(--mb-1-5);
}

.footer_social:hover{
    color: hsl(230, 92%, 85%);
    color: var(--first-color-lighter);
}

.footer_copy{
    font-size: .75rem;
    font-size: var(--smaller-font-size);
    text-align: center;
    color: hsl(230, 8%, 65%);
    color: var(--text-color-light);
    margin-top: 3rem;
    margin-top: var(--mb-3);
}

.footer_title,
.footer_subtitle,
.footer_link,
.footer_social{
    color: white;
}

/* SCROLL UP */
.scrollup{
    position: fixed;
    right: 1rem;
    bottom: -20%;
    background-color: hsl(230, 69%, 61%);
    background-color: var(--first-color);
    opacity: .8;
    padding: 0 .3rem;
    border-radius: .5rem;
    z-index: 10;
    z-index: var(--z-tooltip);
    transition: .4s;
}

.scrollup:hover{
    background-color: hsl(230, 57%, 53%);
    background-color: var(--first-color-alt);
}

.scrollup_icon{
    font-size: 1.5rem;
    color: white;
    margin-top: .3rem;
}

/* SHOW SCROLL */
.show-scroll{
    bottom: 5rem;
}

/* SCROLL BAR */
::-webkit-scrollbar{
    width: .60rem;
    background-color: hsl(230, 12%, 90%);
    background-color: var(--scroll-bar-color);
    border-radius: .5rem;
}

::-webkit-scrollbar-thumb{
    background-color: hsl(230, 12%, 80%);
    background-color: var(--scroll-thumb-color);
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover{
    background-color: hsl(230, 8%, 65%);
    background-color: var(--scroll-thumb-color-hover);
}

/* MEDIA QUERIES */
/* SMALL DEVICES */
@media screen and (max-width: 350px){
    .container{
        margin-left: 1rem;
        margin-left: var(--mb-1);
        margin-right: 1rem;
        margin-right: var(--mb-1);
    }

    .home_blob{
        width: 200px;
    }

    .nav_menu{
        padding: 2rem .25rem 4rem;
    }

    .nav_list{
        grid-column-gap: 0;
        -webkit-column-gap: 0;
                column-gap: 0;
    }

    .home_content{
        grid-template-columns: .25fr 3fr;
    }

    .skills_title{
        font-size: .938rem;
        font-size: var(--normal-font-size);
    }

    .qualification_data{
        grid-gap: .5rem;
        gap: .5rem;
    }

    .services_container{
        grid-template-columns: -webkit-max-content;
        grid-template-columns: max-content;
        justify-content: center;
    }

    .services_content{
        padding-right: 3.5rem;
    }

    .services_modal{
        padding: 0 .5rem;
    }

    .project_img{
        width: 200px;
    }

    .testimonial_data,
    .testimonial_header{
        flex-direction: column;
        align-items: center;
    }

    .testimonial_img{
        margin-right: 0;
        margin-bottom: .25rem;
        margin-bottom: var(--mb-0-25);
    }

    .testimonial_data,
    .testimonial_description{
        text-align: center;
        margin-left: 0;
    }

    .terminal_main {
        width: 22rem;
    }

    .card_container {
        padding: 0rem 1rem 1.35rem 1rem;
        margin: 0 .5rem 5rem .5rem;
    }

    .card_header {
        font-size: 1.5rem;
        font-size: var(--h1-font-size);
    }

    .card_right {
        order: 1;
        padding-top: 1.75rem;
    }
}

/* MEDIUM DEVICES */
@media screen and (min-width: 568px){
    .home_content{
        grid-template-columns: -webkit-max-content 1fr 1fr;
        grid-template-columns: max-content 1fr 1fr;
    }

    .home_data{
        grid-column: initial;
    }

    .home_img{
        order: 1;
        justify-self: center;
    }

    .home_blob{
        width: 220px;
    }

    .about_container{
        grid-template-columns: repeat(2, 1fr);
    }

    .skills_container{
        grid-template-columns: repeat(2, 1fr);
    }

    .qualification_sections{
        display: grid;
        grid-template-columns: .7fr;
        justify-content: center;
    }

    .portfolio_content{
        grid-template-columns: repeat(2, 1fr);
    }

    .project_container{
        grid-template-columns: repeat(2, 1fr);
    }

    .contact_container{
        grid-template-columns: repeat(2, 1fr);
    }

    .footer_container{
        grid-template-columns: repeat(2, 1fr);
    }

    .card_container {
        display: flex;
        padding: 0rem .5rem 0rem 2rem;
        margin: 0 1.5rem 5rem 1.5rem;
    }

    .card_left {
        order: 1;
    }

    .card_right {
        order: 2;
    }

    .card_icon {
        display: unset;
        margin: .75rem 0 1rem 0;
    }

    .card_text {
        margin-right: 1rem;
    }
    
    .card_img {
        width: 95%;
        margin-bottom: 10%;
    }
}

@media screen and (min-width: 768px){
    .container{
        margin-left: auto;
        margin-right: auto;
    }

    body{
        margin: 0;
    }

    .section{
        padding: 6rem 0 2rem;
    }

    .notification{
        -webkit-transform: translate(-50%, 1rem);
                transform: translate(-50%, 1rem);
    }

    .section_subtitle{
        margin-bottom: 4rem;
    }

    .header{
        top: 0;
        bottom: auto;
        bottom: initial;
    }

    .header,
    .main,
    .footer_container{
        padding: 0 1rem ;
    }

    .nav{
        height: calc(3rem + 1.5rem);
        height: calc(var(--header-height) + 1.5rem);
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
                column-gap: 1rem;
    }

    .nav_icon,
    .nav_close,
    .nav_toggle{
        display: none;
    }

    .nav_list{
        display: flex;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
    }

    .nav_menu{
        margin-left: auto;
    }

    .language_switch_container {
        position: absolute;
        left: 9.5rem;
        top: 1.5rem;
    }
    
    .language_switch_box {
        background-color: hsla(0, 0%, 97%, .5);
        background-color: var(--notification-success-color);
        width: 10rem;
        height: 4.5rem;
        border-radius: .75rem;
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter: blur(1rem);
        box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
        z-index: 3;
    }

    .change-theme{
        margin: 0;
    }

    .home_container{
        grid-row-gap: 5rem;
        row-gap: 5rem;
    }

    .home_content{
        padding-top: 5.5rem;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
    }

    .home_blob{
        width: 270px;
    }

    .home_scroll{
        display: block;
    }

    .home_scroll-button{
        margin-left: 3rem;
    }

    .about_container{
        grid-column-gap: 5rem;
        -webkit-column-gap: 5rem;
                column-gap: 5rem;
    }

    .about_img{
        width: 350px;
    }

    .about_description{
        text-align: left;
        text-align: initial;
    }

    .about_info{
        justify-content: space-between;
    }

    .about_buttons{
        justify-content: initial;
    }

    .qualification_tabs{
        justify-content: center;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
    }

    .qualification_button{
        margin: 0 1rem;
        margin: 0 var(--mb-1);
    }

    .qualification_sections{
        grid-template-columns: .5fr;
    }

    .services_container{
        grid-template-columns: repeat(3, 218px);
        justify-content: center;
    }

    .services_icon{
        font-size: 2rem;
    }

    .services_content{
        padding: 4rem 0 2rem 2.5rem;
    }

    .services_modal-content{
        width: 370px;
        height: 280px;
    }

    .services_modal-services{
        padding: .75rem 0 0rem 0rem;
    }

    .portfolio_img{
        width: 320px;
    }

    .portfolio_content{
        align-items: center;
    }

    .project{
        text-align: left;
        text-align: initial;
    }

    .project_bg{
        background: none;
    }

    .project_container{
        background-color: hsl(230, 69%, 61%);
        background-color: var(--first-color-second);
        border-radius: 1.5rem;
        padding: 3rem 2.5rem 0;
        grid-template-columns: 1fr -webkit-max-content;
        grid-template-columns: 1fr max-content;
        grid-column-gap: 3rem;
        -webkit-column-gap: 3rem;
                column-gap: 3rem;
    }

    .project_data{
        padding-top: .8rem;
    }

    .footer_container{
        grid-template-columns: auto -webkit-max-content auto;
        grid-template-columns: auto max-content auto;
    }

    .footer_bg{
        padding: 3rem 0 3.5rem;
    }

    .footer_links{
        flex-direction: row;
        grid-column-gap: 2rem;
        -webkit-column-gap: 2rem;
                column-gap: 2rem;
        -webkit-transform: translateX(1.25rem);
                transform: translateX(1.25rem);
    }

    .footer_socials{
        justify-self: flex-end;
    }

    .footer_copy{
        margin-top: 3.5rem;
    }

    .card_container {
        margin: 0 2.5rem 5rem 2.5rem;
        padding: .75rem 0rem 1.25rem 2rem;
    }

    .card_header {
        font-size: calc(1.5rem + .2rem);
        font-size: calc(var(--h1-font-size) + .2rem);
        margin-bottom: .005rem;
    }

    .card_subheader {
        font-size: calc(.75rem - .05rem);
        font-size: calc(var(--smaller-font-size) - .05rem);
        margin-bottom: 1.5rem;
    }

    .card_text {
        margin-top: 0rem;
        font-size: .938rem;
        font-size: var(--normal-font-size);
    }

    .card_img {
        margin-bottom: 10%;
    }

    .portfolio_heading {
        position: -webkit-sticky;
        position: sticky;
        top: 16%;
    }
    
    .card{
        position: -webkit-sticky;
        position: sticky;
        top: 35%;
    }
    
}

/* For large devices */
@media screen and (min-width: 1024px){

    .notification{
        left: unset;
        right: -100%;
        top: 0%;
        -webkit-transform: unset;
                transform: unset;
        margin-top: 5rem;
    }

    @-webkit-keyframes notification_show {
        100% {
            right: 1%;
        }
    }

    @keyframes notification_show {
        100% {
            right: 1%;
        }
    }

    @-webkit-keyframes notification_hide {
        0% {
            right: 0%;
        }
        100% {
            right: -100%;
        }
    }

    @keyframes notification_hide {
        0% {
            right: 0%;
        }
        100% {
            right: -100%;
        }
    }

    .container{
        margin-left: auto;
        margin-right: auto;
    }

    body{
        margin-left: 0rem;
    }

    .section{
        padding: 6rem 0 2rem;
    }

    .header,
    .footer_container{
        padding: 0rem 6 5rem;
    }

    .main{
        margin-left: 5rem;
    }

    .home_blob{
        width: 350px;
        -webkit-transform: translateY(2.25rem);
                transform: translateY(2.25rem)
    }

    .home_social{
        -webkit-transform: translateX(0rem);
                transform: translateX(0rem);
    }

    .services_container{
        grid-template-columns: repeat(3, 238px);
    }

    .portfolio_content{
        grid-column-gap: 5rem;
        -webkit-column-gap: 5rem;
                column-gap: 5rem;
    }

    .swiper-portfolio-icon{
        font-size: 3.5rem;
    }

    .swiper-button-prev{
        left: -2.5rem; /* currently not working because we have to overwrite the css from swiper-bundle.min.css */
    }

    .swiper-button-next{
        right: -3.5rem;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets{
        bottom: -4.5rem;
    }

    .contact_form{
        width: 460px;
    }

    .contact_inputs{
        grid-template-columns: repeat(2, 1fr);
    }

    .terminal_container {
        -webkit-transform: translateX(-2.5rem);
                transform: translateX(-2.5rem);
    }

    .card_container {
        padding: 0rem 2rem 1.25rem 2rem;
        margin: 0 auto 5rem auto;
        max-width: 60rem;
        max-height: 30rem;
        -webkit-transform: translateX(-40px);
                transform: translateX(-40px)
    }

    .card_img {
        max-width: 90%;
        max-height: 90%;
        margin-left: 5%;
        margin-top: 4%;
    }

    .card_icon {
        display: unset;
        margin: 2rem 0 1rem 0;
    }
}

@media screen and (min-width: 1235px){
    .language_switch_container {
        position: unset;
        margin-top: 2.75rem;
        margin-left: -.5rem;
    }
}

@media screen and (min-width: 1280px){
    body{
        margin: 0;
    }

    .home_blob{
        width: 420px;
        -webkit-transform: translateY(2.25rem);
                transform: translateY(2.25rem)
    }
}
